.menu {
	margin-top: 18px;
	width: 100%;

	.close {
		display: none;
	}

	@media (max-width: $tablet) {
		display: block;
		position: fixed;
		right: -300px;
		top: 0;
		width: 213px;
		height: 100%;
		background: url('../images/bg/menu-bg.png') repeat-y center right;
		z-index: 100;
		margin: 0;
		transition: all 0.5s ease;

		&.opened {
			right: 0;
			transition: all 0.5s ease;
		}

		.close {
			display: block;
			position: absolute;
			right: 30px;
			top: 28px;
			z-index: 10;
			cursor: pointer;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		text-align: center;

		@media (max-width: $tablet) {
			margin: 112px 29px 0 0;
		}

		li {
			display: inline-block;
			list-style: none;
			font-size: 16px;
			line-height: 33px;
			margin: 0 30px;
			text-transform: uppercase;

			@media (max-width: $xlarge) {
				margin: 0 25px;
			}

			@media (max-width: $large) {
				margin: 0 15px;
			}

			@media (max-width: $tablet) {
				width: 100%;
				display: inline-block;
				text-align: right;
				margin: 0 0 5px;

				a {
					color: $white;
					font-size: 16px;
				}
			}

			&.active,
			&.current_page_item {
				font-weight: bold;
				position: relative;

				&::after {
					position: absolute;
					bottom: -3px;
					left: -3px;
					width: 66px;
					height: 8px;
					background: url('../images/icons/line.svg') no-repeat center center;
					content: "";

					@media (max-width: 767px) {
						display: none;
					}
				}
			}
		}
	}
}