.agree {
	a {
		color: #4FB4E5;
		text-decoration: underline;
	}

	p {
		font-size: 15px;
		line-height: 20px;
	}
}

.iagree {
	margin-bottom: 30px;
}

.question {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 26px;

	> label {
		font-size: 20px;
		font-family: $FranklinGothicURW-Med;
	}

	.radio {
		margin-left: 52px;
	}
}

.quiz {
	input[type="text"],
	input[type="email"] {
		width: 100%;
		border: 0;
		height: 60px;
		padding: 0 20px;
		margin-bottom: 20px;
		font-size: 20px;
	}

	.agree {
		p {
			margin-bottom: 30px;
		}
	}
}


.qui {
	display: none;
}

.qui-info {
	margin-bottom: 10px;
}

.check .wpcf7-list-item {
	label {
		input[type=checkbox] {
			position: absolute;
			z-index: -1000;
			left: -1000px;
			overflow: hidden;
			clip: rect(0 0 0 0);
			height: 1px;
			width: 1px;
			margin: -1px;
			padding: 0;
			border: 0;
		}

		input[type=checkbox] + .wpcf7-list-item-label {
			padding-left: 25px;
			height: 20px; 
			display: inline-block;
			background-repeat:no-repeat;
			background-position: 0 0;
			font-size: 15px;
			vertical-align: middle;
			cursor: pointer;
			position: relative;
			top: -2px;
		}

		input[type=checkbox]:checked + .wpcf7-list-item-label {
			background-position: 0 -20px;
		}

		.wpcf7-list-item-label {
			background-image:url('../images/icons/csscheckbox.png');
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
}

.check {
	.wpcf7-not-valid-tip {
		position: absolute;
		left: 14px;
		width: 200px;
		top: 32px;
	}
}

.wpcf7-radio {
	.wpcf7-list-item label {
		input[type=radio] {
			position:absolute;
			z-index:-1000;
			left:-1000px;
			overflow: hidden;
			clip: rect(0 0 0 0);
			height:1px;
			width:1px;
			margin:-1px;
			padding:0;
			border:0;
		}

		input[type=radio] + .wpcf7-list-item-label {
			padding-left:48px;
			height:30px; 
			display:inline-block;
			line-height:30px;
			background-repeat:no-repeat;
			background-position: 0 0;
			font-size:20px;
			vertical-align:middle;
			cursor:pointer;

		}

		input[type=radio]:checked + .wpcf7-list-item-label {
			background-position: 0 -30px;
		}

		.wpcf7-list-item-label {
			background-image:url('../images/icons/cssradiocheckbox.png');
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
}