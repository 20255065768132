.container-fluid {
	max-width: $container-fluid;
}

.container-fluid-full {
	max-width: 100%;
}

html,
body {
	width: 100%;
	height: 100%;
	color: $blue;
	font-family: 'Franklin Gothic Book';
}

a {
	color: $blue;

	&:hover,
	&:focus {
		color: $black;
		outline: none!important;
		text-decoration: none;
	}
}

body {
	background: $grey;
	color: $black;
	font-size: $body-font-size;
}

img {
	max-width: 100%;
}

p {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 30px;
	color: $blue;

	@media (max-width: $tablet) {
		margin-bottom: 20px;
	}
}

*:focus {
    outline: none;
}

.locked {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $blue;
	font-family: $ZoojaPro;
}

.shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: none;
}

.selected-lang {
	span {
		display: none;
	}
}

.translatepress-sl_SI {
	.selected-lang {
		span.slo {
			display: inline-block;
		}
	}
}

.translatepress-en_GB {
	.selected-lang {
		span.en {
			display: inline-block;
		}
	}
}