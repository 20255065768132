/*LAYOUT*/
$container-fluid:        1200px;

/*FONT*/
$body-font-size:   16px;

/*COLORS*/
$grey:        #EDE9E2;
$black:       #000000;
$white:       #FFFFFF;
$blue:        #312B6A;
$green:       #74B743;
$red:         #D21E3A;


/*HEADINGS*/
$h1-size:          30px;
$h2-size:          30px;
$h3-size:          24px;
$h4-size:          18px;
$h5-size:          16px;
$h6-size:          14px;

/*Breakpoints*/
$xlarge: 1140px;
$large: 1024px;
$tablet: 768px;
$tablet-min: 769px;

$FranklinGothicURW-Boo: FranklinGothicURW-Boo;
$FranklinGothicURW-Dem: FranklinGothicURW-Dem;
$FranklinGothicURW-Med: FranklinGothicURW-Med;
$ZoojaPro: ZoojaPro;