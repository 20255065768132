.swiper-container {
	width: 100%;
	padding: 40px 0;

	@media (max-width: $tablet) {
		padding: 20px;
	}
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	width: 50%;
	flex-direction: column;

	@media (max-width: $tablet) {
		width: 100%;
	}

	img {
		position: relative;
		z-index: 1;

		@media (max-width: $tablet) {
			max-width: 80%;
		}
	}

	h2 {
		font-size: 100px;
		opacity: 0.3;
		line-height: 100px;
		margin-top: -30px;

		@media (max-width: $tablet) {
			font-size: 80px;
			line-height: 80px;
			margin-top: -35px;
		}
	}

	p {
		margin-top: -30px;

		@media (max-width: $tablet) {
			font-size: 15px;
			margin-top: -25px;
		}
	}
}

.swiper-button-prev {
	width: 132px;
	height: 63px;
	background: url('../images/icons/arrow-left.svg') no-repeat;

	@media (max-width: $large) {
		width: 100px;
		height: 48px;
		background-size: 100px 48px;
	}

	@media (max-width: $tablet) {
		width: 56px;
		height: 27px;
		background-size: 56px 27px;
		left: 0;
	}
}

.swiper-button-next {
	width: 132px;
	height: 63px;
	background: url('../images/icons/arrow-right.svg') no-repeat;
	cursor: pointer;

	@media (max-width: $large) {
		width: 100px;
		height: 48px;
		background-size: 100px 48px;
	}

	@media (max-width: $tablet) {
		width: 56px;
		height: 27px;
		background-size: 56px 27px;
		right: 0;
	}
}

.swiper-content {
	opacity: 0;
	max-width: 630px;
	transition: all 0.3s ease;

	@media (max-width: $tablet) {
		max-width: 100%;
		opacity: 1;
	}
}

.swiper-slide-active {
	.swiper-content {
		opacity: 1;
		transition: all 0.5s ease;
	}
}

.more {
	display: inline-block;
	background: url('../images/temp/btn-more.svg') no-repeat center center;
	width: 141px;
	height: 33px;
	color: $white;
	text-align: center;
	font-weight: bold;
	font-size: 15px;
	padding-top: 5px;

	&:hover,
	&:focus {
		color: $white;
	}

	@media (max-width: $tablet) {
		width: 117px;
		height: 27px;
		background-size: 117px 27px;
		font-size: 12px;
	}
}

.arrows {
	position: relative;
	top: -306px;

	@media (max-width: $tablet) {
		top: -275px;
	}
}

.slide-bottles {
	position: fixed;
	right: 20px;
	bottom: 30px;
	width: 300px;
	height: 70px;

	.swiper-container {
		padding: 0;
	}

	@media (max-width: $tablet) {
		display: none;
	}
}

/**/
.swiper-slider {
	@media (max-width: 670px) {
		height: 560px;

		.sola-slide {
			margin-top: -220px;
		}

		.swiper-content {
			margin-top: -160px;
		}

		.swiper-slide h2 {
    		font-size: 57px;
		}
	}
}

.sola-slide {
	position: relative;

	@media (max-width: $large) {
		transform: scale(0.8, 0.8);
	}

	@media (max-width: $tablet) {
		transform: scale(0.7, 0.7);
	}

	@media (max-width: 670px) {
		transform: scale(0.4, 0.4);
	}
}

.sola-slide-1 {
	width: 607px;
	height: 527px;
	margin-bottom: 20px;

	.ss-1-2 {
		position: absolute;
		left: -38px;
		top: 236px;
	}

	.ss-1-3 {
		position: absolute;
		left: 151px;
		top: -9px;
		z-index: 10;
	}

	.ss-1-4 {
		position: absolute;
		left: 397px;
		top: 0;
	}

	.ss-1-5 {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 5;
	}

	.ss-1-6 {
		position: absolute;
		left: 75px;
		top: 5px;
	}

	.ss-1-7 {
		position: absolute;
		right: 0;
		bottom: 58px;
	}

	.ss-1-8 {
		position: absolute;
		right: 79px;
		bottom: 160px;
	}

	.ss-1-9 {
		position: absolute;
		left: -4px;
		top: 139px;
	}

	.ss-1-10 {
		position: absolute;
		right: 85px;
		bottom: 0;
	}

	.ss-1-11 {
		position: absolute;
		left: -47px;
		bottom: 0px;

	}

	.ss-1-12 {
		position: absolute;
		right: 11px;
		top: 121px;
	}
}

.sola-slide-2 {
	width: 683px;
	height: 445px;
	margin-top: 83px;
	margin-bottom: 21px;

	.ss-1-2 {
		position: absolute;
		left: 177px;
		top: -90px;
	}

	.ss-1-3 {
		position: absolute;
		right: 0;
		top: 134px;
	}

	.ss-1-4 {
		position: absolute;
		right: 145px;
		top: 91px;
	}

	.ss-1-5 {
		position: absolute;
		right: 262px;
		bottom: 36px;
	}

	.ss-1-6 {
		position: absolute;
		right: 238px;
		top: 58px;
	}

	.ss-1-7 {
		position: absolute;
		right: 7px;
		bottom: 12px;
	}

	.ss-1-8 {
		position: absolute;
		right: 13px;
		bottom: 35px;
	}
}

.sola-slide-3 {
	width: 683px;
	height: 445px;
	margin-top: 0;
	margin-bottom: 113px;

	.ss-1-2 {
		position: absolute;
		left: 160px;
		top: 23px;
	}

	.ss-1-3 {
		position: absolute;
		right: 149px;
		bottom: -153px;
	}

	.ss-1-4 {
		position: absolute;
		    right: -30px;
    top: 25px;
	}

	.ss-1-5 {
		position: absolute;
		    right: 10px;
    bottom: -108px;
	}

	.ss-1-6 {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.ss-1-7 {
		position: absolute;
		left: 66px;
		top: 34px;
	}
}

.sola-slide-4 {
	width: 804px;
	height: 572px;
	margin-top: 0;

	.ss-1-2 {
		position: absolute;
		left: 363px;
		top: -14px;
		z-index: 5;
	}

	.ss-1-3 {
		position: absolute;
		right: 175px;
		bottom: 134px;
		z-index: 30;
	}

	.ss-1-4 {
		position: absolute;
		right: 150px;
		bottom: 205px;
		z-index: 15;
	}

	.ss-1-5 {
		position: absolute;
		right: 63px;
		bottom: 188px;
	}

	.ss-1-6 {
		position: absolute;
		right: 227px;
		bottom: 62px;
		z-index: 10;
	}

	.ss-1-7 {
		position: absolute;
		left: 277px;
		top: 40px;
	}

	.ss-1-8 {
		position: absolute;
		right: 220px;
		bottom: 63px;
		z-index: 20;
	}
}

.sola-slide-5 {
	width: 580px;
	height: 471px;
	margin-top: 53px;
	margin-bottom: 28px;

	.ss-1-2 {
		position: absolute;
		left: 101px;
		top: -39px;
	}

	.ss-1-3 {
		position: absolute;
		left: 0;
		top: 8px;
	}

	.ss-1-4 {
		position: absolute;
		left: 22px;
		bottom: 21px;
	}

	.ss-1-5 {
		position: absolute;
		left: 336px;
		bottom: 26px;
	}

	.ss-1-6 {
		position: absolute;
		left: 184px;
		bottom: 53px;
	}

	.ss-1-7 {
		position: absolute;
		right: 54px;
		top: 35px;
	}

	.ss-1-8 {
		position: absolute;
		left: 17px;
		bottom: 69px;
	}
}

.sola-slide-6 {
	width: 700px;
	height: 587px;

	.ss-1-2 {
		position: absolute;
		left: 275px;
		top: -17px;
		z-index: 10;
	}

	.ss-1-3 {
		position: absolute;
		left: 212px;
		bottom: -6px;
	}

	.ss-1-4 {
		position: absolute;
		right: 76px;
		bottom: 65px;
	}

	.ss-1-5 {
		position: absolute;
		right: 116px;
		top: 35px;
	}

	.ss-1-6 {
		position: absolute;
		right: 5px;
		bottom: 125px;
	}
}

.sola-slide-7 {
	width: 670px;
	height: 488px;
	margin-top: 37px;
	margin-bottom: 20px;

	.ss-1-2 {
		position: absolute;
		left: 160px;
		top: -53px;
	}

	.ss-1-3 {
		position: absolute;
		left: 118px;
		top: 42px;
	}

	.ss-1-4 {
		position: absolute;
		left: 191px;
		top: 165px;
	}

	.ss-1-5 {
		position: absolute;
		right: 120px;
		top: 50px;
	}

	.ss-1-6 {
		position: absolute;
		left: 130px;
		bottom: 0;
	}

	.ss-1-7 {
		position: absolute;
		left: 94px;
		top: 132px;
	}

	.ss-1-8 {
		position: absolute;
		left: 36px;
		top: 230px;
	}

	.ss-1-9 {
		position: absolute;
		right: 147px;
		bottom: -29px;
	}

	.ss-1-10 {
		position: absolute;
		left: 214px;
		top: 41px;
	}

	.ss-1-11 {
		position: absolute;
		left: 247px;
		bottom: -37px;
	}

	.ss-1-12 {
		position: absolute;
		left: 110px;
		top: -38px;
	}
}

.sola-slide-8 {
	width: 700px;
	height: 587px;

	.ss-1-2 {
		position: absolute;
		left: 274px;
		top: -7px;
		z-index: 10;
	}

	.ss-1-3 {
		position: absolute;
		right: 198px;
		bottom: 45px;
		z-index: 20;
	}

	.ss-1-4 {
		position: absolute;
		right: -10px;
		top: 139px;
	}

	.ss-1-5 {
		position: absolute;
		left: 356px;
		top: 70px;
	}

	.ss-1-6 {
		position: absolute;
		left: 182px;
		top: 26px;
	}

	.ss-1-7 {
		position: absolute;
		right: -11px;
		bottom: 251px;
	}

	.ss-1-8 {
		position: absolute;
		right: 84px;
		top: 23px;
	}
}

.sola-slide-9 {
	width: 680px;
	height: 638px;
	margin-top: -40px;

	.ss-1-2 {
		position: absolute;
		left: 137px;
		top: 39px;
		z-index: 20;
	}

	.ss-1-3 {
		position: absolute;
		left: 313px;
		bottom: 15px;
	}

	.ss-1-4 {
		position: absolute;
		right: 47px;
		bottom: 65px;
	}

	.ss-1-5 {
		position: absolute;
		right: 157px;
		top: 168px;
	}

	.ss-1-6 {
		position: absolute;
		left: 31px;
		bottom: 152px;
	}

	.ss-1-7 {
		position: absolute;
		right: 178px;
		bottom: 131px;
	}

	.ss-1-8 {
		position: absolute;
		left: 152px;
		top: 213px;
		z-index: 10;
	}

	.ss-1-9 {
		position: absolute;
		left: 82px;
		bottom: 116px;
	}

	.ss-1-10 {
		position: absolute;
		left: 120px;
		top: 86px;
	}

	.ss-1-11 {
		position: absolute;
		left: 242px;
		bottom: -3px;
	}
}

.sola-slide-10 {
	width: 615px;
	height: 484px;
	margin-top: 90px;

	.ss-1-2 {
		position: absolute;
		left: 196px;
		top: -98px;
		z-index: 10;
	}

	.ss-1-3 {
		position: absolute;
		left: 197px;
		bottom: 43px;
	}

	.ss-1-4 {
		position: absolute;
		right: 171px;
		bottom: 36px;
	}

	.ss-1-5 {
		position: absolute;
		left: 183px;
		top: 68px;
	}

	.ss-1-6 {
		position: absolute;
		right: 7px;
		top: 138px;
	}

	.ss-1-7 {
		position: absolute;
		right: 27px;
		top: -99px;
	}

	.ss-1-8 {
		position: absolute;
		left: 203px;
		top: -21px;
	}
}

.sc-10 {
	margin-top: -30px;	
}