footer {
	display: inline-block;
	width: 100%;
	text-align: center;
	background: url('../images/bg/footer-left.png') no-repeat left bottom;

	@media (max-width: 767px) {
		background: url('../images/bg/left.png') no-repeat left bottom;

		p {
			font-size: 14px;
		}
	}
	
	p {
		margin-bottom: 0;
	}

	.footer {
		display: inline-block;
		width: 100%;
		height: 203px;
		padding-top: 152px;
		background: url('../images/bg/footer-right.png') no-repeat right bottom;

		@media (max-width: 767px) {
			padding-top: 100px;
			height: 153px;
			background: url('../images/bg/right.png') no-repeat right bottom;
		}
	}
}