main {
	overflow: hidden;
}

.separator {
	display: inline-block;
	width: 100%;
}

.hr-vred {
	position: relative;
	display: inline-block;
	z-index: 10;

	a {
		padding: 6px 0 6px 38px;
		background: url('../images/icons/more.svg') no-repeat center left;
		font-size: 14px;
		font-weight: bold;
	}

	.hr-vred-cont {
		position: absolute;
		left: 26px;
		top: 19px;
		text-align: left;
		display: none;
		width: 240px;

		&.active {
			display: block;
		}

		p {
			background: url('../images/icons/h-line.svg') no-repeat left bottom;
			margin: 0 0 10px;
			padding-bottom: 12px;
			padding-left: 12px;
			font-size: 14px;
		}

		table {
			margin-left: 12px;
			font-size: 14px;

			td {
				padding-right: 10px;
				padding-bottom: 2px;
				vertical-align: top;

				&:nth-child(2) {
					text-align: right;
					font-weight: bold;
					color: #4FB4E5;
				}
			}
		}
	}
}

.img-sola-1 {
	position: relative;
	right: -57px;
}

.product {
	img {
		max-width: initial;
	}

	.product-wrap {
		h4 {
			display: none;
		}

		img {
			@media (max-width: 1024px) {
				max-width: 145% !important;
			}
		}
	}

	&.product-10 {
		.product-wrap {
			img {
				@media (max-width: 1024px) {
					max-width: 165% !important;
				}

				@media (max-width: 767px) {
					max-width: 80% !important;
				}
			}
		}
	}

	&.product-1 {
		@media (max-width: 767px) {
			margin-top: 60px !important;

			.or2 {
				margin-top: -70px;
			}

			.product-wrap {
				.or1 {
					img {
						margin-top: -80px !important;
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		text-align: center;
		padding-top: 0 !important;
		margin-top: 0 !important;
		margin-bottom: 90px !important;

		.product-left,
		.product-right {
			margin-top: 0 !important;
		}

		p {
			text-align: center;
			font-size: 15px !important;
			line-height: 20px !important;
		}

		.or1 {
			order: 1;
		}

		.or2 {
			order: 2;
		}

		.product-wrap {
			background: none !important;
			padding-bottom: 0 !important;

			h4 {
				display: block;
				font-size: 80px;
				opacity: 0.3;
			}

			img {
				max-width: 80% !important;
				right: 0 !important;
				left: 0 !important;
				margin: 0 0 20px !important;

				&.bottle-icons {
					margin-bottom: 20px !important;
				}
			}

			.or1 {
				img {
					margin-top: -40px !important;
				}
			}

		}

		.text-right {
			text-align: center !important;
		}

		.dotes-1,
		.dotes-2 {
			display: none;
		}
	}
}

.product-1 {
	background: url('../images/temp/clouds-1.png') no-repeat center top 276px;
	margin-bottom: 30px;

	@media (max-width: 767px) {
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: 50px;
			left: 50%;
			margin-left: -70px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-ice-tea-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.dotes-1 {
		position: relative;

		&::before {
			position: absolute;
			left: 190px;
			top: 0;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-1.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 140px;
	}

	.sola-ice-tea-breskev {
		float: right;
		position: relative;
		right: -156px;
		padding-top: 130px;
	}
}

.product-2 {
	background: url('../images/temp/cloud-2.png') no-repeat center top 13px;
	margin-bottom: 30px;
	margin-top: 130px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			right: -70px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-ice-tea-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.img-sola-2 {
		margin-bottom: 120px;
		margin-left: -165px;
	}

	.dotes-2 {
		position: relative;

		&::before {
			position: absolute;
			left: 583px;
			top: -148px;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-2.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 100px;
	}

	.sola-ice-tea-brusnica {
		float: left;
		position: relative;
		left: -45px;
		padding-top: 130px;
	}
}

.product-3 {
	background: url('../images/temp/clouds-1.png') no-repeat center top 276px;
	margin-bottom: 30px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			left: -70px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-caj-bottom.png') no-repeat center bottom 30px;
		padding-bottom: 120px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.dotes-1 {
		position: relative;

		&::before {
			position: absolute;
			left: 490px;
			top: 0;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-1.svg') no-repeat;
		}

		&::after {
			position: absolute;
			left: -246px;
			top: 0;
			width: 344px;
			height: 115px;
			content: "";
			background: url('../images/temp/cloud-3.png') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 0;
	}

	.sola-caj {
		float: right;
		position: relative;
		right: -30px;
		padding-top: 130px;
	}
}

.product-4 {
	background: url('../images/temp/cloud-2b.png') no-repeat center top 175px;
	margin-bottom: 30px;
	padding-top: 100px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			right: -30px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-ice-tea-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.img-sola-2 {
		margin-bottom: 120px;
		margin-left: -220px;
	}

	.dotes-2 {
		position: relative;

		&::before {
			position: absolute;
			left: 583px;
			top: -148px;
			width: 204px;
			height: 250px;
			content: "";
			background: url('../images/temp/dotes-2.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 78px;
	}

	.sola-multi {
		float: left;
		position: relative;
		margin-left: 54px;
		padding-top: 130px;
		margin-bottom: 20px;
	}
}

.product-5 {
	margin-bottom: 90px;
	padding-top: 142px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -60px;
			left: -70px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-limonada-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.dotes-1 {
		position: relative;

		&::before {
			position: absolute;
			left: 520px;
			top: -135px;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-1.svg') no-repeat;
		}

		&::after {
			position: absolute;
			left: -246px;
			top: 220px;
			width: 344px;
			height: 115px;
			content: "";
			background: url('../images/temp/cloud-3.png') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 140px;
	}

	.sola-limonada {
		float: right;
		position: relative;
		right: -100px;
		padding-top: 130px;
		margin-bottom: 140px;
	}
}

.product-6 {
	background: url('../images/temp/cloud-2b.png') no-repeat center top 175px;
	margin-bottom: 30px;
	padding-top: 100px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			right: -20px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-sabesa-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.img-sola-2 {
		margin-bottom: 120px;
		margin-left: -140px;
	}

	.dotes-2 {
		position: relative;

		&::before {
			position: absolute;
			left: 583px;
			top: -148px;
			width: 204px;
			height: 250px;
			content: "";
			background: url('../images/temp/dotes-2.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 78px;
	}

	.sola-sabesa {
		float: left;
		position: relative;
		margin-left: 60px;
		padding-top: 130px;
		margin-bottom: 10px;
	}
}

.product-7 {
	background: url('../images/temp/clouds-1.png') no-repeat center top 276px;
	margin-bottom: 60px;
	padding-top: 160px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			left: -40px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-breskev-bottom.png') no-repeat center bottom 30px;
		padding-bottom: 120px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.dotes-1 {
		position: relative;

		&::before {
			position: absolute;
			left: 470px;
			top: -140px;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-1.svg') no-repeat;
		}

		&::after {
			position: absolute;
			left: -246px;
			top: 0;
			width: 344px;
			height: 115px;
			content: "";
			background: url('../images/temp/cloud-3.png') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 0;
	}

	.sola-breskev {
		float: right;
		position: relative;
		right: -30px;
		padding-top: 130px;
	}
}

.product-8 {
	background: url('../images/temp/cloud-2b.png') no-repeat center top 175px;
	margin-bottom: 30px;
	padding-top: 100px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			right: -40px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-jabolko-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.img-sola-2 {
		margin-bottom: 120px;
		margin-left: -140px;
	}

	.dotes-2 {
		position: relative;

		&::before {
			position: absolute;
			left: 583px;
			top: -148px;
			width: 204px;
			height: 250px;
			content: "";
			background: url('../images/temp/dotes-2.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 78px;
	}

	.sola-jabolko {
		float: left;
		position: relative;
		margin-left: 60px;
		padding-top: 130px;
		margin-bottom: 10px;
	}
}

.product-9 {
	background: url('../images/temp/clouds-1.png') no-repeat center top 276px;
	margin-bottom: 30px;
	padding-top: 190px;

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -70px;
			left: -40px;
			z-index: -1;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-pomaranca-bottom.png') no-repeat center bottom 30px;
		padding-bottom: 120px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.dotes-1 {
		position: relative;

		&::before {
			position: absolute;
			left: 490px;
			top: -160px;
			width: 204px;
			height: 229px;
			content: "";
			background: url('../images/temp/dotes-1.svg') no-repeat;
		}

		&::after {
			position: absolute;
			left: -246px;
			top: -85px;
			width: 344px;
			height: 115px;
			content: "";
			background: url('../images/temp/cloud-3.png') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 0;
	}

	.img-sola-1 {
		position: relative;
		right: -17px;
	}

	.sola-pomaranca {
		float: right;
		position: relative;
		right: -30px;
		padding-top: 130px;
	}
}

.product-10 {
	background: url('../images/temp/cloud-2b.png') no-repeat center top 175px;
	margin-bottom: 30px;
	padding-top: 100px;

	.isosport-mobile {
		display: none;
	}

	@media (max-width: 767px) {
		position: relative;
		background: none;

		&::after {
			position: absolute;
			width: 141px;
			height: 47px;
			background: url('../images/temp/small-cloud.png') no-repeat;
			content: "";
			top: -50px;
			right: -30px;
			z-index: -1;
		}

		.isosport {
			display: none;
		}

		.isosport-mobile {
			display: inline-block;
		}
	}

	.product-wrap {
		background: url('../images/titles/sola-isosport-bottom.png') no-repeat center bottom 30px;
	}

	.bottle-icons {
		margin-bottom: 30px;
	}

	.img-sola-2 {
		margin-bottom: 120px;
		margin-left: -258px;
	}

	.dotes-2 {
		position: relative;

		&::before {
			position: absolute;
			left: 583px;
			top: -148px;
			width: 204px;
			height: 250px;
			content: "";
			background: url('../images/temp/dotes-2.svg') no-repeat;
		}
	}

	p {
		font-size: 20px;
		line-height: 25px;
	}

	.product-left {
		margin-top: 78px;
	}

	.sola-isosport {
		float: left;
		position: relative;
		margin-left: 60px;
		padding-top: 130px;
		margin-bottom: 10px;
	}
}

.contact-page {
	max-width: 812px;

	.spacer {
		display: block;
		height: 85px;

		@media (max-width: 767px) {
			height: 30px;
		}
	}

	@media (max-width: 767px) {
		max-width: 100%;

		img {
			display: none;
		}

		.or1 {
			order: 1;
		}

		.or2 {
			order: 2;
		}
	}

	h2 {
		display: none;

		@media (max-width: 767px) {
			display: block;
			font-size: 80px;
			opacity: 0.3;
			text-align: center;
			margin-top: 30px;
			margin-bottom: 20px;
		}
	}

	p {
		font-weight: normal;
		font-size: 20px;
		line-height: 25px;
		font-family: 'Franklin Gothic Book';

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 20px;
			text-align: center;
		}

		b,
		strong {
			font-family: $FranklinGothicURW-Med;
			font-weight: normal;
		}
	}

	input,
	textarea {
		&::placeholder {
		  font-family: 'Franklin Gothic Book';
		  opacity: 0.45;
		}

		&:-ms-input-placeholder {
		  opacity: 0.45;
		  font-family: 'Franklin Gothic Book';
		}

		&::-ms-input-placeholder {
		  opacity: 0.45;
		  font-family: 'Franklin Gothic Book';
		}

		&:focus {
			border-color: $blue;
		}
	}

	input {
		width: 100%;
		margin-bottom: 20px;
		height: 60px;
		border: 2px $white solid;
		padding: 0 20px;
		font-family: $FranklinGothicURW-Med;
		color: $blue;
		font-size: 20px;
		line-height: 33px;

		@media (max-width: 767px) {
			height: 46px;
			margin-bottom: 8px;
		}
	}

	textarea {
		width: 100%;
		margin-bottom: 32px;
		height: 175px;
		border: 2px $white solid;
		padding: 13px 20px;
		font-size: 20px;
		line-height: 33px;

		@media (max-width: 767px) {
			height: 162px;
		}
	}

	button,
	.wpcf7-submit {
		width: 141px;
		height: 33px;
		background: url('../images/icons/cta.svg') no-repeat;
		text-align: center;
		color: $white;
		font-family: $FranklinGothicURW-Med;
		font-size: 15px;
		border: 0;
		text-transform: uppercase;
		cursor: pointer;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		padding: 0;
		margin: 0;

		@media (max-width: 767px) {
			padding-left: 14px;
		}

		li {
			list-style: none;
			margin-bottom: 4px;

			a {
				position: relative;
				font-size: 20px;
				line-height: 20px;
				text-decoration: underline;

				@media (max-width: 767px) {
					font-size: 15px;
					line-height: 20px;
				}

				&:hover {
					text-decoration: none;
				}

				&:after {
					position: absolute;
					left: -15px;
					top: 8px;
					width: 9px;
					height: 9px;
					background: url('../images/icons/dot.svg') no-repeat;
					content: "";

					@media (max-width: 767px) {
						top: 4px;
					}
				}
			}
		}
	}

	.aside-info {
		@media (max-width: 767px) {
			margin-top: 50px;

			p {
				text-align: left;
			}
		}
	}
}

.contact-title {
	margin-left: -83px;
}

.contform {
	padding-left: 46px;

	@media (max-width: 767px) {
		padding: 0;
	}
}

.pagesola {
	max-width: 812px;

	.title img {
		margin-left: -83px;
		margin-bottom: 40px;
	}

	h2 {
		display: none;

		@media (max-width: 767px) {
			display: block;
			font-size: 80px;
			opacity: 0.3;
			text-align: center;
			margin-top: 30px;
			margin-bottom: 40px;
		}
	}

	h4 {
		font-family: $FranklinGothicURW-Med;
		font-weight: normal;
		margin-bottom: 30px;
		font-size: 25px;

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 20px;
		}
	}

	h5 {
		font-weight: normal;
		font-size: 20px;
		line-height: 25px;
		font-family: $FranklinGothicURW-Med;
		margin-bottom: 10px;

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 20px;
		}
	}

	p {
		font-weight: normal;
		font-size: 20px;
		line-height: 25px;
		font-family: 'Franklin Gothic Book';

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 20px;
		}

		b,
		strong {
			font-family: $FranklinGothicURW-Med;
			font-weight: normal;
		}
	}

	@media (max-width: 767px) {
		max-width: 100%;

		.post-thumbnail {
			display: none;
		}
	}

	ul {
		padding: 0;
		margin: 0;

		@media (max-width: 767px) {
			padding-left: 14px;
		}

		li {
			list-style: none;
			margin-bottom: 4px;

			a {
				position: relative;
				font-size: 20px;
				line-height: 20px;
				text-decoration: underline;

				@media (max-width: 767px) {
					font-size: 15px;
					line-height: 20px;
				}

				&:hover {
					text-decoration: none;
				}

				&:after {
					position: absolute;
					left: -15px;
					top: 8px;
					width: 9px;
					height: 9px;
					background: url('../images/icons/dot.svg') no-repeat;
					content: "";

					@media (max-width: 767px) {
						top: 4px;
					}
				}
			}
		}
	}
}

.hid {
	overflow-x: hidden;
}

.video-section {
	max-width: 1070px;
	background: url('../images/icons/video-elements.svg') no-repeat center top -55px;
	padding-top: 90px;
	padding-bottom: 50px;
	margin-top: 55px;
	margin-bottom: 96px;

	@media (max-width: 767px) {
		background-size: 100% 100%;
		background-position: 0;
		padding-top: 66px;
		padding-bottom: 70px;
		margin: 0 0 20px;
	}

	.swiper-button-prev, .swiper-button-next {
		margin-top: -25px;

		@media (max-width: 670px) {
		    margin-top: -5px;
		}
	}

	.swiper-container {
		padding: 0;
	}

	.video {
		a {
			&::after {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 106px;
				height: 108px;
				background: url('../images/icons/play.svg') no-repeat;
				content: "";
				z-index: 10;
				margin: -53px 0 0 -54px;

				@media (max-width: 767px) {
					width: 36px;
					height: 36px;
					background-size: 36px;
					margin: -18px 0 0 -18px;
				}
			}
		}
	}
}

.quiz {
	width: 100%;
	background: #312b6a url('../images/bg/blue-bg.jpg') repeat-x center top;
	margin-bottom: 80px;
	color: $white;
	text-align: center;
	padding-top: 120px;
	padding-bottom: 66px;

	@media (max-width: 1024px) {
		padding-top: 100px;
	}

	@media (max-width: 767px) {
		padding: 65px 45px 45px;
	}

	.container-fluid {
		position: relative;
		max-width: 1032px;
	}

	.cloud {
		position: absolute;
		width: 306px;
		height: 102px;
		background: url('../images/temp/cloud-q.png') no-repeat;

		@media (max-width: 1024px) {
			width: 190px;
			height: 65px;
			background-size: cover;
		}

		@media (max-width: 767px) {
			width: 120px;
			height: 41px;
			background-size: cover;
		}

		&.cloud-1 {
			left: -263px;
			top: 23px;

			@media (max-width: 767px) {
				top: initial;
				bottom: 307px;
				left: -50px;
			}
		}

		&.cloud-2 {
			left: 174px;
			top: -164px;

			@media (max-width: 1024px) {
				top: -144px;
			}

			@media (max-width: 767px) {
				top: -86px;
				left: 0;
			}
		}

		&.cloud-3 {
			left: 6px;
			bottom: -118px;

			@media (max-width: 1024px) {
				bottom: -98px;
			}

			@media (max-width: 767px) {
				left: 212px;
				bottom: -59px;
			}
		}

		&.cloud-4 {
			right: 18px;
			top: -66px;

			@media (max-width: 1024px) {
				top: -46px;
			}

			@media (max-width: 767px) {
				top: 211px;
				right: -64px;
			}
		}
	}

	.mb {
		margin-bottom: 78px;

		@media (max-width: 767px) {
			margin: 0 auto;
			max-width: 350px;
			margin-bottom: 50px;
		}
	}

	p {
		color: $white;
		display: inline-block;
		max-width: 762px;
		margin-bottom: 20px;
		z-index: 2;
		position: relative;

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 20px;
		}
	}

	.pimg-1 {
		position: relative;

		@media (max-width: 767px) {
			margin-bottom: 50px;
		}

		.pi {
			position: absolute;
			left: -27px;
			top: -24px;
			width: 119%;
			max-width: initial;
		}
	}

	.pimg-2 {
		position: relative;

		@media (max-width: 767px) {
			margin-bottom: 50px;
			z-index: 2;
		}

		.pi {
			position: absolute;
			left: -23px;
			top: -23px;
			width: 119%;
			max-width: initial;
		}
	}

	.pimg-3 {
		position: relative;

		@media (max-width: 767px) {
			z-index: 2;
		}

		.pi {
			position: absolute;
			left: -20px;
			top: -40px;
			width: 134%;
			max-width: initial;
		}
	}

	.wpcf7-submit,
	.btn {
		display: inline-block;
		width: 142px;
		height: 33px;
		background: url('../images/icons/cta-w.svg') no-repeat;
		text-align: center;
		color: $blue;
		padding-top: 4px;
		font-family: $FranklinGothicURW-Dem;
		text-transform: uppercase;
		border: 0;
	}
}

.insta {
	max-width: 1032px;
	text-align: center;

	@media (max-width: 767px) {
		max-width: 80%;

		.col-4 {
			padding: 0 5px;
		}
	}

	.title {
		margin-bottom: 102px;

		@media (max-width: 767px) {
			width: 163px;
			margin-bottom: 30px;
		}
	}
}

.instagram-gallery {

	.col-4 {
		img {
			margin-bottom: 24px;

			@media (max-width: 767px) {
				margin-bottom: 10px;
			}
		}
	}
}

