header {
	padding: 0 30px;

	@media (max-width: $xlarge) {
		padding: 0;
	}

	.float-right {
		margin-top: 17px;

		@media (max-width: $tablet) {
			display: none;
		}

		img {
			margin-left: 16px;
			position: relative;
			top: 4px;

			@media (max-width: $xlarge) {
				margin-left: 8px;
			}
		}
	}

	.logo {
		@media (max-width: $xlarge) {
			img {
				width: 110px;
			}
		}

		@media (max-width: $tablet) {
			display: none;
		}
	}

	.mobile-logo {
		display: none;

		@media (max-width: $tablet) {
			display: inline-block;
			margin-top: 16px;
		}
	}

	@media (max-width: $tablet) {
		.col-6 {
			text-align: center;
		}
	}
}

.lang {
	float: right;
	width: 130px;
	height: 33px;
	background: url('../images/temp/lang.svg') no-repeat center center;
	color: $white;
	text-align: center;
	margin-left: 30px;
	font-size: 15px;

	@media (max-width: $xlarge) {
		margin-left: 15px;
		width: 85px;
		background-size: 85px;
		font-size: 12px;
		padding-top: 3px;
	}

	.selected-lang {
		margin-bottom: 10px;

		a,
		span {
			width: 100%;
			position: relative;
			padding-top: 6px;
			color: $white;

			&::after {
				position: absolute;
				right: 21px;
				top: 15px;
				width: 8px;
				height: 5px;
				background: url('../images/icons/down.svg') no-repeat center center;
				content: "";

				@media (max-width: $xlarge) {
					right: 15px;
					top: 13px;
				}
			}
		}

		&.opened {
			a {
				&::after {
					transform: rotate(-180deg)
				}
			}
		}
	}

	ul {
		padding: 0;
		display: none;

		li {
			display: inline-block;
			width: 100%;
			list-style: none;
			margin-bottom: 7px;
			border-bottom: 1px $blue dotted;

			a {
				display: inline-block;
				width: 100%;

				&:hover {
					background: $blue;
					color: $white;
				}
			}

			&:nth-child(1) {
				transform: rotate(7deg);
			}

			&:nth-child(2) {
				transform: rotate(-6deg);
			}
		}
	}
}

.hamburger {
	display: none;
	float: right;
	margin-top: 24px;

	@media (max-width: $tablet) {
		display: block;
	}
}